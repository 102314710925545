import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/index.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import App from './routes/App';
import What from './routes/What';
import Why from './routes/Why';
import How from './routes/How';
import Contact from './routes/Contact';
import DocViewer from './routes/DocViewer';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />}></Route>
      <Route path='what-is-governance' element={<What />}></Route>
      <Route path='why-is-governance-important' element={<Why />}></Route>
      <Route path='how-do-i-get-involved' element={<How />}></Route>
      <Route path='contact-us' element={<Contact />}></Route>
      <Route path='document/:docname/:docid' element={<DocViewer />} />
    </Routes>
  </BrowserRouter>
);

reportWebVitals();
