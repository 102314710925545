import { ReactElement } from 'react';
import { Header } from '../components/header';
import { BodyGrid } from '../layout/bodyGrid';
import { Navigation } from '../components/navigation';
import { Body } from '../layout/body';

function What(): ReactElement {
  return (
    <div className='App'>
      <Header />
      <BodyGrid>
        <Navigation />
        <Body>
          <div>
            According to Merriam-Webster and to put it simply, governance is
            “the act or process of governing or overseeing the control and
            direction of something (such as a country or an organization).” For
            the purposes of this instance we are talking about governance of a
            blockchain, generally and Telos, specifically.
          </div>
          <div>
            As is stated, the definition of governance is quite broad and can
            span from simple organizations like a school club to national or
            even international organizations. In the case of Telos, we are
            talking about a blockchain that has members all over the world.
            Governance is an act or series of actions, a process and the rules
            and regulations that define what is allowed within the organization,
            how the organization conducts its business and possibly how
            individual members agree to act.
          </div>
          <div>
            Ethics, risk management, compliance and administration are all
            elements of governance. Antelope based blockchains, such as Telos
            are capable of defining complex systems of governance on chain and
            executing that governance with code. This creates an incredibly
            transparent system that is both robust and flexible. Applications
            and users can make use of the governance system on Telos and even
            implement their own governance utilizing the Decide Governance
            Engine.
          </div>
        </Body>
      </BodyGrid>
    </div>
  );
}

export default What;
