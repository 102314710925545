import { ReactElement } from 'react';
import { FixedWidthCentered } from '../layout/fixedWidthCentered';
import telosGovernance from '../images/telos-governance.svg';

function Header(): ReactElement {
  return (
    <header className='Header'>
      <FixedWidthCentered>
        <div className='Header--center'>
          <img src={telosGovernance} />
        </div>
      </FixedWidthCentered>
    </header>
  );
}

export { Header };
