import { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { JsonRpc } from 'eosjs';

function DocumentList(): ReactElement {
  const [docs, setDocs] = useState<any>(null);
  useEffect(() => {
    const getDocs = async (): Promise<void> => {
      const rpc = new JsonRpc('https://mainnet.telos.net');
      const docsArr = await rpc.get_table_rows({
        json: true,
        code: 'amend.decide',
        scope: 'amend.decide',
        table: 'documents',
        limit: 100,
        reverse: false,
        show_payer: false,
      });
      setDocs(docsArr.rows);
    };
    getDocs();
  }, []);
  return (
    <nav className='DocumentList'>
      <ul>
        {docs &&
          docs.map((doc: any, index: number) => (
            <>
              {index === 0 && (
                <Link
                  to={`/document/${doc.title
                    .toLowerCase()
                    .replace(/ /g, '_')}/${doc.document_name}`}
                  key={`${doc.title}-${index}`}
                >
                  <li>{doc.title}</li>
                </Link>
              )}
            </>
          ))}
      </ul>
    </nav>
  );
}

export { DocumentList };
