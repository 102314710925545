import { ReactElement, useRef, useEffect, useState } from 'react';
import { Header } from '../components/header';
import { BodyGrid } from '../layout/bodyGrid';
import { Navigation } from '../components/navigation';
import { Body } from '../layout/body';
import { useParams } from 'react-router-dom';
import { JsonRpc } from 'eosjs';
import { DateTime } from '../components/dateTime';
import ReactMarkdown from 'react-markdown';
import { initData } from '../data/initData';

function DocViewer(): ReactElement {
  const [expectedSections, setExpectedSections] = useState<number>(0);
  const [sections, setSections] = useState<any[]>([]);

  // eslint-disable-next-line no-console
  console.log(expectedSections);

  const [errors, setErrors] = useState<any>([]);
  const params = useParams();
  const scope = params.docid;

  const ref = useRef({
    loading: 0,
  });

  const addError = (err: string): void => {
    setErrors((oldArray: any) => [...oldArray, err]);
  };

  useEffect(() => {
    if (sections.length) {
      localStorage.setItem('dStorCache', JSON.stringify(sections));
    }
  }, [sections]);

  useEffect(() => {
    const localCache =
      localStorage.getItem('dStorCache') || JSON.stringify(initData);
    const dStorCache = JSON.parse(localCache);
    setSections(dStorCache);
  }, []);

  // const resolveCid = async (cid: string): Promise<any> => {
  //   const controller = new AbortController();
  //   const id = setTimeout(() => controller.abort(), 10000);
  // const response = await fetch(`https://api.dstor.cloud/ipfs/${cid}`, {
  //   method: 'GET',
  //   headers: {
  //     'Content-Type': 'application/json;charset=utf-8',
  //   },
  //   signal: controller.signal,
  // });
  //   clearTimeout(id);
  //   const bodyText = await response.text();
  //   return { bodyText };
  // };

  // const getMarkDownFromDstor = async (sectionsArr: any[]): Promise<void> => {
  //   for (let i = 0; i < sectionsArr.length; i++) {
  //     try {
  //       const ipfsResult = await resolveCid(sectionsArr[i].content);
  //       if (ipfsResult) {
  //         const adition = { ...sectionsArr[i], ...ipfsResult };
  //         setSections((oldArray: any) => [...oldArray, adition]);
  //       }
  //     } catch (err: any) {
  //       addError(`Connection timed out: ${sectionsArr[i].content}`);
  //     }
  //   }
  // };

  const getMarkDownFromDstor = async (sectionsArr: any[]): Promise<void> => {
    const promiseArray = [];
    for (let i = 0; i < sectionsArr.length; i++) {
      if (
        sectionsArr[i].content !==
        'QmNSsoUpPY1x9ZQtdGFc1ia9xL3zSnjtUsrxHxpNxdTMx8'
      ) {
        promiseArray.push(
          fetch(`https://api.dstor.cloud/ipfs/${sectionsArr[i].content}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },
          })
        );
      }
    }
    const resArr: any = await Promise.allSettled(promiseArray);
    const textArr = [];
    for (let i = 0; i < resArr.length; i++) {
      textArr.push(await resArr[i].value.text());
    }
    const finalArray = [];
    for (let i = 0; i < sectionsArr.length; i++) {
      finalArray.push({ ...sectionsArr[i], ...{ bodyText: textArr[i] } });
    }
    setSections(finalArray);
  };

  const getDocsFromContract = async (nowNum: number): Promise<void> => {
    if (ref.current.loading === nowNum) {
      const rpc = new JsonRpc('https://mainnet.telos.net');
      const sectionsArr = await rpc.get_table_rows({
        json: true,
        code: 'amend.decide',
        scope,
        table: 'sections',
        limit: 100,
        reverse: false,
        show_payer: false,
      });
      setExpectedSections(sectionsArr.rows.length);
      if (sectionsArr.rows.length > 0) {
        getMarkDownFromDstor(sectionsArr.rows);
      } else {
        addError(
          'No sections were found for this document on the contact amend.decide'
        );
      }
    }
  };

  useEffect(() => {
    const now = Date.now();
    if (ref.current.loading === 0) ref.current.loading = now;
    sections.length === 0 && getDocsFromContract(now);
  }, []);

  return (
    <div className='DocViewer'>
      <Header />
      <BodyGrid>
        <Navigation />
        <Body>
          <div className='DocViewer--title'>
            {/* {params.docname?.replace(/_/g, ' ')} */}
          </div>
          <div>
            <div>{/* Loaded {sections.length} of {expectedSections} */}</div>
            {errors &&
              errors.map((error: any, index: number) => (
                <div key={`${error}-${index}`}>{error}</div>
              ))}

            {sections &&
              sections
                .sort((a, b) => {
                  return a.section_number - b.section_number;
                })
                .map((section: any, index: number) => (
                  <div key={`a-${index}`}>
                    {section.section_number > 0 && (
                      <div
                        key={`${section.section_name}-${index}`}
                        className='DocViewer--section-layout'
                      >
                        {/* <div className='DocViewer--section-number'>
                          Section: {section.section_number - 1}
                        </div> */}
                        <div className='DocViewer--section-body'>
                          <ReactMarkdown>{section.bodyText}</ReactMarkdown>
                        </div>
                        <div className='DocViewer--section-cid'>
                          dStor CID:{' '}
                          <a
                            href={`https://api.dstor.cloud/ipfs/${section.content}`}
                            target='_blank'
                          >
                            {section.content}
                          </a>
                        </div>
                        <div className='DocViewer--section-amended'>
                          Amended:{' '}
                          <DateTime
                            showTime
                            value={new Date(section.last_amended)}
                          />{' '}
                          by{' '}
                          <a
                            href={`https://eosauthority.com/account/${section.amended_by}?network=telos`}
                            target='_blank'
                          >
                            {section.amended_by}
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
          </div>
        </Body>
      </BodyGrid>
    </div>
  );
}

export default DocViewer;
