import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

function Navigation(): ReactElement {
  return (
    <nav className='Navigation'>
      <ul>
        <Link to='/'>
          <li>Documents</li>
        </Link>
        {/* <Link to='/what-is-governance'>
          <li>What is Governance?</li>
        </Link>
        <Link to='/why-is-governance-important'>
          <li>Why is it important?</li>
        </Link>
        <Link to='/how-do-i-get-involved'>
          <li>How do I get involved?</li>
        </Link>
        <Link to='/contact-us'>
          <li>Contact Us</li>
        </Link> */}
      </ul>
    </nav>
  );
}

export { Navigation };
