import { ReactElement } from 'react';

export interface PropsShape {
  showTime?: boolean;
  showDay?: boolean;
  alwaysShowYear?: boolean;
  value: Date | undefined;
}

const DateTime = ({
  value,
  showTime = false,
  showDay = true,
  alwaysShowYear = false,
}: PropsShape): ReactElement => {
  const addZeroBefore = (n: number): string => {
    return (n < 10 ? '0' : '') + n;
  };

  const locale = (): string => {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return navigator.language || 'en-gb';
    }
  };

  const month = value?.toLocaleDateString(locale(), {
    month: 'short',
  });
  const day = value?.toLocaleDateString(locale(), {
    day: 'numeric',
  });
  const isCurrentYear =
    `${new Date().getFullYear()}` ===
    value?.toLocaleDateString(locale(), { year: 'numeric' });
  const year =
    alwaysShowYear || !isCurrentYear
      ? `${value?.toLocaleDateString(locale(), { year: 'numeric' })}`
      : '';

  if (value)
    return (
      <>
        {month}
        {showDay && ` ${day}`}
        {showDay && year && ','}
        {year && ` ${year}`}
        {showTime &&
          ` - ${addZeroBefore(value?.getHours())}:${addZeroBefore(
            value?.getMinutes()
          )}`}
      </>
    );
  else return <></>;
};
export { DateTime };
