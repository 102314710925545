import { ReactElement } from 'react';
import { Header } from '../components/header';
import { BodyGrid } from '../layout/bodyGrid';
import { Navigation } from '../components/navigation';
import { Body } from '../layout/body';

function Contact(): ReactElement {
  return (
    <div className='App'>
      <Header />
      <BodyGrid>
        <Navigation />
        <Body>
          <div>Lets talk.</div>
          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eu
            nulla nec dolor faucibus molestie ac non arcu. Ut quis semper nibh.
            Vestibulum orci nunc, vehicula sed iaculis ut, vehicula eget enim.
            Curabitur et orci quis tortor tincidunt varius. Donec bibendum
            semper tellus nec ornare. Proin odio velit, convallis vitae
            fringilla in, venenatis a leo. Mauris ac enim scelerisque, lobortis
            sem vel, bibendum nisi. In nulla urna, facilisis ut nunc quis,
            fermentum dictum ex. Sed vehicula turpis quis nulla laoreet, in
            tristique nisi rhoncus. Phasellus eu purus id erat sollicitudin
            sagittis. Nullam quis leo vitae purus feugiat venenatis feugiat ac
            augue. Class aptent taciti sociosqu ad litora torquent per conubia
            nostra, per inceptos himenaeos. Duis et diam bibendum, commodo neque
            posuere, pulvinar urna.
          </div>
        </Body>
      </BodyGrid>
    </div>
  );
}

export default Contact;
