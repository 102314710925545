import { ReactElement } from 'react';
import { Header } from '../components/header';
import { BodyGrid } from '../layout/bodyGrid';
import { Navigation } from '../components/navigation';
import { Body } from '../layout/body';

function How(): ReactElement {
  return (
    <div className='App'>
      <Header />
      <BodyGrid>
        <Navigation />
        <Body>
          <div>
            You can start by reading the Telos governance documents on this site
            and learning about how Telos is governed. And if you havent already,
            check out telos.net. This site is managed by the Telos Foundation an
            on-chain organization supported by the network for the purpose of
            promoting the network and educating people about Telos and the
            opportunities on the blockchain. From Telos.Net, you can get a free
            Telos account on the blockchain, and learn how to acquire TLOS, the
            network token, which allows you to participate in Telos governance,
            from creating proposals to amend the Telos governance or to receive
            funding for projects that benefit the Telos ecosystem; to vote on
            amendments and proposals and participate in elections and polls.
          </div>
          <div></div>
        </Body>
      </BodyGrid>
    </div>
  );
}

export default How;
