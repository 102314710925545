import { ReactElement } from 'react';
import { FixedWidthCentered } from '../layout/fixedWidthCentered';

interface PropsShape {
  children: ReactElement[];
}

function BodyGrid({ children }: PropsShape): ReactElement {
  return (
    <FixedWidthCentered>
      <div className='BodyGrid'>
        <>{children[0]}</>
        <>{children[1]}</>
      </div>
    </FixedWidthCentered>
  );
}

export { BodyGrid };
