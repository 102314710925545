import { ReactElement } from 'react';

interface PropsShape {
  children: ReactElement[];
}

function Body({ children }: PropsShape): ReactElement {
  return (
    <nav className='Body'>
      <h1 className='Body--title'>{children[0]}</h1>
      <>{children[1]}</>
    </nav>
  );
}

export { Body };
