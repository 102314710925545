import { ReactElement } from 'react';
import { Header } from '../components/header';
import { BodyGrid } from '../layout/bodyGrid';
import { Navigation } from '../components/navigation';
import { Body } from '../layout/body';

function Why(): ReactElement {
  return (
    <div className='App'>
      <Header />
      <BodyGrid>
        <Navigation />
        <Body>
          <div>
            Generally speaking, good governance helps members to always act in
            the best interests of an organization.
          </div>
          <div>
            More specifically in the case of Telos, it can improve the
            performance, help the blockchain be more stable and productive, and
            unlock new opportunities. Good Governance also reduces risks and
            increases security, enabling faster and safer growth. In turn, this
            also improves reputation and fosters trust amongst the community of
            users.
          </div>
        </Body>
      </BodyGrid>
    </div>
  );
}

export default Why;
