import { ReactElement } from 'react';

interface PropsShape {
  children: ReactElement | ReactElement[] | string;
  maxWidth?: number;
  alignItems?: string;
}

function FixedWidthCentered({
  children,
  maxWidth = 1200,
  alignItems = 'center',
}: PropsShape): ReactElement {
  return (
    <div
      className='FixedWidthCentered'
      style={{
        gridTemplateColumns: `1fr minmax(0, ${maxWidth}px) 1fr`,
        alignItems,
      }}
    >
      <div className='FixedWidthCentered--center'>{children}</div>
    </div>
  );
}

export { FixedWidthCentered };
