import { ReactElement } from 'react';
import { Header } from '../components/header';
import { BodyGrid } from '../layout/bodyGrid';
import { Navigation } from '../components/navigation';
import { Body } from '../layout/body';
import { DocumentList } from '../components/documentList';

function App(): ReactElement {
  return (
    <div className='App'>
      <Header />
      <BodyGrid>
        <Navigation />
        <Body>
          <div>
            The Telos Governance Documents were created at the inception of the
            network and over time have been updated by the network users via the
            Telos Amend process.
          </div>
          <div>
            <p>
              Below you will find Telos Blockchain Network Operating Agreement
              (TBNOA) as most recently amended. Eventually the other governing
              documents referenced in the TBNOA will be included. These
              governing documents together represent the rule book for the way
              in which the Telos Blockchain Network operates and how users of
              the network may interact with the network.
            </p>
            <DocumentList />
          </div>
        </Body>
      </BodyGrid>
    </div>
  );
}

export default App;
